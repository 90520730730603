import { parse } from "query-string"
import React, { useEffect, useRef } from "react"
import { useIntl } from "gatsby-plugin-intl"

export const urlPattern = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
export const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-.\/:;<>=?@\[\]{}\\^_`~]).{8,14}$/
export const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const namePattern = /^[a-z ,.'-]+$/i
export const emailRegex = new RegExp(emailPattern)
export const urlRegex = new RegExp(urlPattern)
export const nameRegex = new RegExp(namePattern)

export const getQueryParams = query => {
  return parse(query)
}

export const ucFirst = str => {
  if (!str) return str

  return str[0].toUpperCase() + str.slice(1)
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useTranslation = () => {
  const intl = useIntl()
  return {
    t: text => {
      try {
        return intl.formatMessage({ id: text })
      } catch (e) {
        return text
      }
    },
    locale: intl.locale,
  }
}

export const openHubspotChat = () => {
  if (
    window &&
    window.HubSpotConversations &&
    window.HubSpotConversations.widget
  ) {
    window.HubSpotConversations.widget.open()
  }
}

export const getCurrentYear = () => new Date().getFullYear()
